const categories = [
  {
    title: "Community",
    id: "community",
    expertise: true
  },
  {
    title: "Creatives",
    id: "creative",
    expertise: true
  },
  {
    title: "Education",
    id: "educator",
    expertise: true
  },
  {
    title: "Entrepreneur/Business",
    id: "entrepreneur",
    expertise: true
  },
  {
    title: "Fashion",
    id: "fashion",
    expertise: true
  },
  {
    title: "Organization",
    id: "org",
    expertise: true
  },
  {
    title: "Press/Media",
    id: "press",
    expertise: true
  },
  {
    title: "Tech",
    id: "tech",
    expertise: true
  },
  {
    title: "Writers",
    id: "writer",
    expertise: true
  }
];

export default categories;
