import React from "react";
import Img from "gatsby-image";
import MapIcon from "../../icons/map";
import LinkIcon from "../../icons/link";
import TwitterIcon from "../../icons/twitter";
import styles from "./profile.module.scss";
import Button from "../button";

const Profile = props => {
  return (
    <div
      className={styles.profile}
      style={{
        "--profile-theme-color": props.hex === "#FFFFFF" ? "#1da1f2" : props.hex
      }}
    >
      {props.sizes ? (
        <Img
          alt={`${props.name}'s avatar on Twitter.'`}
          sizes={props.sizes}
          backgroundColor
          className={styles.image}
        />
      ) : (
        <img
          className={styles.grayImage}
          alt={`${props.name}'s avatar on Twitter.'`}
          src={props.image.replace("_normal", "_400x400")}
        />
      )}
      <h2 className={styles.name}>{props.name}</h2>
      <p className={styles.handle} style={{marginBottom: "8px"}}>
      <a
        href={`https://twitter.com/${props.handle}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.handle}
      >
      <span className={styles.linkText}>
          <TwitterIcon style={{ color: "var(--profile-theme-color)" }} />@{props.handle}
        </span>
      </a>
      </p>
        {props.expandedUrl ? (
      <div className={styles.url}>
        <LinkIcon size={14} />
          <a href={props.expandedUrl} 
          target="_blank" 
          rel="noopener noreferrer">
            {props.displayUrl}
          </a>
      </div>
        ) : (
              null
        )}
      <p
        className={styles.description}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
    </div>
  );
};

export default Profile;
